<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-light">Nombre de SMS disponible : </div>
                    </div>
                    <div class="card-body text-center">
                            <div class="display-1 font-weight-bold m-0 p-0">2500</div>
                    </div>
                </div>
            </v-col>
                <v-col cols="12" md="3">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-light">Nombre de campagne effectuer:</div>
                    </div>
                    <div class="card-body text-center">
                        <div class="display-1 font-weight-bold m-0 p-0">5</div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="text-right" >
                <v-btn color="primary" class="mr-2" style="border-radius:0px" @click="showDialogValidate()" v-if="form.contact.length > 0 && form.message != null  && form.message != '' && form.title != null  && form.title != '' ">
                    Envoyer <i class="fa-solid fa-paper-plane ml-2"></i> 
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <!-- Left pannel -->
            <v-col cols="12" md="8">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-bold">Formulaire d'envoie</div>
                    </div>
                    <div class="card-body">
                        <v-row class="mr-2">
                            <v-col cols="12" md="12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Titre de campagne</label>
                                    <input type="email" class="form-control b-0 mt-1" v-model="form.title">
                                    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                                </div>
                                <!-- <div class="row">
                                    <el-input placeholder="..." v-model="form.title">
                                        <template slot="prepend">Titre de campagne : </template>
                                    </el-input>
                                </div>                                 -->
                            </v-col>
                        </v-row>
                        <v-row class="mr-2">
                            <v-col cols="12" md="12">
                                <div class="row m-2">
                                    <div class="col-3 subtitle-1 font-weight-light">Expediteur :</div>
                                    <div class="col margin-bottom">
                                            <div  style="width:100%">
                                                <ui-select style="border:1px"
                                                    :options="expediteurOption"
                                                    v-model="expediteurSelect"
                                                ></ui-select>
                                            </div>
                                        </div>
                                </div>                                
                            </v-col>
                        </v-row>
                        <v-row class="mr-2 mb-2">
                            <v-col cols="12" md="12">
                                <div class="row">
                                    <div class="col-3 subtitle-1 font-weight-light">Choisir le model</div>
                                    <div class="col margin-bottom">
                                            <div  style="width:100%">
                                                <ui-select
                                                    :options="modelOption"
                                                    v-model="modelSelect"
                                                    @change="ChanModel()"
                                                ></ui-select>
                                            </div>
                                        </div>
                                </div>                                
                            </v-col>
                        </v-row> 
                        <v-row>
                            <v-col cols="12" md="12" class="mt-0">
                                <el-input type="textarea" placeholder="Saisir le message" :autosize="{ minRows: 10, maxRows: 12 }" v-model="form.message" maxlength="40" style="border-radius:0px" show-word-limit></el-input>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>

            <!-- right pannel -->
            <v-col cols="12" md="4">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-bold">Details d'envoie</div>
                    </div>
                    <div class="card-body text-left">
                        <v-row>
                                <v-col cols="12" md="12">
                                <div class="card" style="border-radius:0px;">
                                    <div class="card-header text-center">
                                        <div v-if="form.contact.length >0" class="subtitle-1 font-weight-light"><b> Total de contact : {{form.contact.length}} </b></div>
                                        <div v-else class="subtitle-1 font-weight-light">Aucun contact  </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12">
                                <div class="subtitle-1 font-weight-light">Choix du/des destinataire(s) </div>
                            </v-col>
                            <v-col cols="12" md="12">
                                <el-radio-group v-model="destinataireType">
                                    <el-radio-button label="Utilisateur"></el-radio-button>
                                    <el-radio-button label="Groupe d'utilisateur"></el-radio-button>
                                    <el-radio-button label="Manuelle"></el-radio-button>
                                </el-radio-group>
                            </v-col>
                        </v-row>
                        <el-divider></el-divider>
                        <v-row>
                            <v-col cols="12" md="12" v-if="destinataireType =='Utilisateur'">
                                <label class="mb-2"><b> Choisir les utilisateurs </b></label>
                                <div class="data-picker" style="width:100%">
                                    <ui-select
                                        :options="userOption"
                                        has-search
                                        multiple
                                        v-model="userSelect"
                                    ></ui-select>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" v-else-if="destinataireType =='Manuelle'">
                                <label class="mb-2"><b> Saisir les numéros </b></label>
                                <small id="emailHelp" class="form-text text-muted"> (Utiliser une virgule après chaque numéro)</small>
                                <el-input type="textarea" placeholder="658974751,678987453,..." :autosize="{ minRows: 10, maxRows: 12 }" v-model="contactManuelle" style="border-radius:0px"></el-input>

                            </v-col>
                            <v-col cols="12" md="12" v-else>
                                <label class="mb-2"><b> Choisir le groupe </b></label>
                                <div class="data-picker" style="width:100%">
                                    <ui-select
                                        :options="groupeOption"
                                        v-model="groupeSelect"
                                    ></ui-select>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    
    export default {

        data() {
            return {

                expediteurOption : [
                    {label: 'CERVO', value: 1},
                    {label: 'INFOS', value: 2}
                ],

                modelOption: [
                    {label: 'Aucun', value: ''},
                    {label: 'envoie 1', value: 'Ceci est le model numero 1 ...'},
                    {label: 'test de grpu', value: 'Ceci est le model numero 2 ...'},
                    {label: 'exemple de promos', value: 'Ceci est le model numero 3 ...'},
                    {label: 'notification sur grc', value: 'Ceci est le model numero 4 ...'}
                ],

                userOption : [
                    {label: 'kevin', value: 697884860},
                    {label: 'cabrel', value: 694758142}
                ],

                groupeOption : [
                    {label: 'groupe 1', value: [697884860,6874126231,59874126,87963247,978456354,5589347832,289753923,789463251]},
                    {label: 'groupe 2', value:  [697884860,6874126231,59874126,87963247,978456354,5589347832,289753923,78946321]}
                ],
                expediteurSelect: '',
                modelSelect : '',
                userSelect: [],
                groupeSelect: '',
                contactManuelle: '',

                form:{
                    title : '',
                    message: '',
                    contact: []
                },

                destinataireType: 'Utilisateur',
            };  
        },

        methods: {

            ChanModel(){
                this.form.message = this.modelSelect.value;
            },
            showDialogValidate(){

                this.$swal({
                title: 'Confirmer ?',
                text: "Vous êtes sur le point d'effecuer l'envoie",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#007FFF',
                cancelButtonColor: '#EE9D06',
                confirmButtonText: 'Oui'
                }).then((result) => {
                if (result.value) {
                    this.sendMessage();
                }
                });
            },
            sendMessage(){
                this.$swal(
                        'Enregistrer !',
                        'Envoie effectuer avec succès',
                        'success'
                    );
            },
            checkNumber(lists){
                
                console.log('lists', lists);
                let array_out = [];

                lists.forEach(element => {
                
                console.log('element', element);

                    if(!array_out.includes(element)) {
                        
                        element = element.toString();
                        console.log('include', element);
                        console.log('lengt :', element.length);

                        if (element.length == 9) {
                            array_out.push(element);
                        }
                    }
                });


                console.log('out', array_out);

                return array_out;   
            },
        },

        watch: {

            userSelect : function (params) {

                let user = [];
                this.userSelect.forEach(element => {
                    user.push(element.value);
                });

                this.form.contact =  this.checkNumber(user);
            },

            groupeSelect: function () {
                this.form.contact = this.checkNumber(this.groupeSelect.value);
            },

            contactManuelle: function () {
                let user = this.contactManuelle.split(',');
                this.form.contact = this.checkNumber(user);
            }

        }
        
    }
</script>